var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "antv-chart-mini" }, [
    _c(
      "div",
      { staticClass: "chart-wrapper", style: { height: 46 } },
      [
        _c(
          "v-chart",
          {
            attrs: {
              "force-fit": true,
              height: _vm.height,
              data: _vm.data,
              scale: _vm.scale,
              padding: [36, 0, 18, 0],
            },
          },
          [
            _c("v-tooltip"),
            _c("v-smooth-area", { attrs: { position: "x*y" } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
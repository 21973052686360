var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { width: _vm.width == null ? "auto" : _vm.width + "px" } },
    [
      _c(
        "v-chart",
        {
          attrs: {
            forceFit: _vm.width == null,
            height: _vm.height,
            data: _vm.data,
            padding: "0",
          },
        },
        [_c("v-tooltip"), _c("v-bar", { attrs: { position: "x*y" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }